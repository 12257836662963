import React from 'react'
import atx from "../assets/atx.png";
import htx from "../assets/htx.png";
import dfw from "../assets/dfw.png";
import sa from "../assets/SA.png";
import tx from "../assets/tx.png";
import G from "../assets/Google.png";
import fLogo from "../assets/F-Logo4.png";
import mLogo from "../assets/M-logo4.png";
import logo from "../assets/MBT Hand Logo2.png";
import phone from "../assets/telephone.png";
import email from "../assets/mail.png";
import text from "../assets/textColor.png";
import hand from "../assets/MBT Hand Logo-Dark.png";
import her from '../assets/woman (1).png';
import him from '../assets/man (1).png';
import them from '../assets/couple (1).png';
import preg from '../assets/pregnant.png';
import { Link } from 'react-router-dom';

function DefaultLayout(props) {
    const city = props.city;
    const user = props.userType;
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="myNAV p-2">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <a href="/">
                            <img src={hand} width="80" />
                        </a>
                        {props.city ?<>
                        <Link to={`/${city}/for`}>
                            <img src={city === "Austin" ? atx : city === "Dallas-DFW" ? dfw : city === "SanAntonio" ? sa : city === "Houston" ? htx : tx} width="55" className="navIMG" />
                        </Link>
                        <img src={user === "him" ? him : user === "her" ? her : user === "them" ? them : preg} width="40" className="navIMG" />
                        </>:""}

                    </div>
                    <div className='dropdown text-end'>
                        <button type="button" className="btn btn-warning dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false" >
                            {"Menu "}
                        </button>
                        <ul
                            className="dropdown-menu text-small"
                            data-popper-placement="bottom-end"
                        >
                            <li className="d-inline-flex align-items-center">
                                <a className="dropdown-item" href="/">
                                    Home
                                </a>
                            </li>
                            {user && user !== "him" ? <li className="d-inline-flex align-items-center">
                                <a className="dropdown-item" href="#book">
                                    Book Appointment
                                </a>
                            </li> : ""}
                            <li className="d-inline-flex align-items-center">
                                <a className="dropdown-item" href="#contact">
                                    Contact Us
                                </a>
                            </li>
                            {user && user !== "him" ? <li className="d-inline-flex align-items-center">
                                <a className="dropdown-item" href="/volunteer">
                                    Volunteer
                                </a>
                            </li> 
                            : ""}
                            <li className="d-inline-flex align-items-center">
                                <a className="dropdown-item" href="https://maps.app.goo.gl/KEDsgzgpJzr7ts5f8?g_st=ic" target="_blank">
                                    Find Us on Google
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr />
            <div className="content">{props.children}</div>
            <hr />
            <div>
                {user === "him" ? <img src={mLogo} width="165" /> : user === "her" ? <img src={fLogo} width="165"/> : <img src={logo} width="115" />}
                <p className={(user === "pregnant") || (user === "them") ? "pt-3" : "no-pad"}>&copy;2024 Mobile Body Therapy</p>
                <div id="contact" className='d-flex justify-content-around pt-2'>
                    <div>
                        <a href="tel:7372310251" style={{ textDecoration: "none", color: "black" }}>
                            <img src={phone} width="25" />
                            <p>Call</p>
                        </a>
                    </div>
                    <div>
                        <a href="sms:7372310251" style={{ textDecoration: "none", color: "black" }}>
                            <img src={text} width="30" />
                            <p>Text</p>
                        </a>
                    </div>
                    <div>
                        <a href={`mailto:Admin@MobileBodyTherapy.com?subject=${city} -`} style={{ textDecoration: "none", color: "black" }}>
                            <img src={email} width="25" />
                            <p>Email</p>
                        </a>
                    </div>
                    <div>
                        <a href="https://maps.app.goo.gl/KEDsgzgpJzr7ts5f8?g_st=ic" style={{ textDecoration: "none", color: "black" }}>
                            <img src={G} width="30" />
                            <p>Google</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultLayout