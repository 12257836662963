import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Content from '../components/Content';
import DefaultLayout from '../components/DefaultLayout';
import SignUp from '../components/SignUp';

function Her({match}) {
  const city = useParams();
  console.log(city)
  useEffect(()=>{
    if (city.cityName === "SanAntonio")
    document.title ="San Antonio's Home for Pregnant Women's Tantric, Yoni & Perineal Massage | Mobile Body Therapy";
    else
    document.title = `${city.cityName}'s Home for Pregnant Women's Tantric, Yoni & Perineal Massage | Mobile Body Therapy`;
  },[]);
  return (
    <DefaultLayout city={city.cityName} userType="her">
    <Content userType="her"/>
    <SignUp userType="her"/>
    </DefaultLayout>
  )
}

export default Her